/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: Header
 * Description: Represents the header section of the web app.
 */

import React, { FC, useState, useEffect } from 'react';
import { UserNav } from '@components/common';
import AsideMobile from '../asideMobile';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import useRouter from 'hooks/router';
import { useNotification } from '../../../context/NotificationContext';

interface RouteLink {
    path: string;
    title: string;
    icon: string;
    smallScreen?: any;
}

const Header: FC<{ activeRoute: RouteLink }> = ({ activeRoute }) => {
    const [open, setOpen] = useState(false);
    const [openNotificationPopup, setOpenNotificationPopup] = useState(false);
    const [openAuthPopup, setOpenAuthPopup] = useState(false);
    const [isBurgerVisible, setIsBurgerVisible] = useState(false);
    const { unreadCount } = useNotification();

    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const router = useRouter();

    const openAsidNew = () => {
        setOpen(prev => !prev);
    };

    const openNotification = () => {
        setOpenNotificationPopup(prev => !prev);
        setOpenAuthPopup(false);
    };

    const openAuth = () => {
        setOpenAuthPopup(prev => !prev);
        setOpenNotificationPopup(false);
    };

    useEffect(() => {
        const checkBurgerVisibility = () => {
            const isVisible = window.innerWidth <= 768;
            setIsBurgerVisible(isVisible);
        };

        checkBurgerVisibility();
        window.addEventListener('resize', checkBurgerVisibility);

        return () => {
            window.removeEventListener('resize', checkBurgerVisibility);
        };
    }, []);

    return (
        <>
            <Container padding={activeRoute?.title === 'Customer Reported Appointments' && '0'}>
                <Flex>
                    {_userData?.user?.role === 'ADMIN' ? (
                        ''
                    ) : isBurgerVisible ? (
                        <Bars onClick={openAsidNew} smallScreen={activeRoute?.smallScreen}>
                            <BarIcon className="far fa-bars"></BarIcon>
                            {unreadCount > 0 && (
                                <NotificationBadge>{unreadCount}</NotificationBadge>
                            )}
                        </Bars>
                    ) : null}
                    <Title>
                        <Icon
                            className={`${activeRoute?.icon}`}
                            onClick={() =>
                                activeRoute?.path ? router.navigate(activeRoute?.path) : ''
                            }
                            style={{ cursor: activeRoute?.path ? 'pointer' : 'auto' }}></Icon>
                        {activeRoute?.title}
                    </Title>
                </Flex>
                {_userData?.user_login === 'customer' && (
                    <UserNav
                        openNotification={openNotification}
                        openNotificationPopup={openNotificationPopup}
                        setOpenAuthPopup={setOpenAuthPopup}
                        openAuth={openAuth}
                        openAuthPopup={openAuthPopup}
                        userData={_userData}
                    />
                )}
            </Container>
            {open &&
                (_userData?.user?.role === 'ADMIN' ? (
                    ''
                ) : (
                    <AsideMobile
                        open={open}
                        openAsidNew={openAsidNew}
                        user={
                            <UserNav
                                openNotification={openNotification}
                                openNotificationPopup={openNotificationPopup}
                                setOpenAuthPopup={setOpenAuthPopup}
                                openAuth={openAuth}
                                openAuthPopup={openAuthPopup}
                                userData={_userData}
                            />
                        }
                    />
                ))}
        </>
    );
};

const NotificationBadge = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    background: red;
    color: white;
    font-size: 0.7rem;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
`;

const Container = styled.div<any>`
    height: 64px;
    width: 100%;
    padding: ${({ padding }) => (padding ? padding : '1rem 1.5rem')};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-left: 1rem;
`;

const Bars = styled.h1<any>`
    position: relative;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    ${media.xs`
        display: ${({ smallScreen }: any) => (smallScreen ? 'none' : 'flex')};
    `}
`;

const BarIcon = styled.i`
    font-size: 1.5rem;
    margin-right: 1rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 1.5rem;
    margin-right: 1rem;
`;

export default Header;
