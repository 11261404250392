import React, { createContext, useContext, useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import { api } from 'helpers/auth-axios';

// Интерфейс контекста
export interface NotificationContextProps {
    unreadCount: number;
    fetchUnreadCount: () => Promise<void>;
    incrementUnreadCount: () => void;
    decrementUnreadCount: (count: number) => void;
}

// Создание контекста
const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

// Провайдер контекста
export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [unreadCount, setUnreadCount] = useState(0);
    const [user, setUser] = useState(
        () => JSON.parse(localStorage.getItem('user') || '{}')?.user || {}
    );

    const userId = user?._id;
    const businessId = user?.business_id?._id;
    const userRole = user?.role;

    // Получение количества непрочитанных уведомлений с сервера
    const fetchUnreadCount = async () => {
        try {
            if (!userId || !businessId) return;
            const response = await api.get<{ count: number }>('/notifications/unread-count');
            setUnreadCount(response.data.count);
        } catch (error) {
            console.error('Error fetching unread notifications count:', error);
        }
    };

    // Увеличение счётчика уведомлений
    const incrementUnreadCount = () => {
        setUnreadCount(prev => prev + 1);
    };

    // Уменьшение счётчика уведомлений
    const decrementUnreadCount = (count: number) => {
        setUnreadCount(prev => Math.max(0, prev - count));
    };

    // Инициализация счётчика при монтировании компонента
    useEffect(() => {
        if (userId && businessId) {
            fetchUnreadCount();
        }
    }, [userId, businessId]);

    // Подключение к Pusher
    useEffect(() => {
        if (!userId || !businessId || !userRole) return;

        const PusherId = process.env.REACT_APP_PUSHER || '';
        const pusher = new Pusher(PusherId, { cluster: 'mt1' });

        const channelName = userRole === 'SERVICE_PROVIDER' ? userId : businessId;
        const channel = pusher.subscribe(channelName);

        channel.bind('eBookingSystem', (data: any) => {
            if (data?.title) {
                incrementUnreadCount();
            }
        });

        channel.bind('pusher:subscription_error', (error: any) => {
            console.error('Pusher subscription error:', error);
        });

        return () => {
            pusher.unsubscribe(channelName);
        };
    }, [userId, businessId, userRole]);

    // Динамическое обновление пользователя из localStorage
    useEffect(() => {
        const handleStorageChange = () => {
            const updatedUser = JSON.parse(localStorage.getItem('user') || '{}')?.user || {};
            setUser(updatedUser);
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <NotificationContext.Provider
            value={{
                unreadCount,
                fetchUnreadCount,
                incrementUnreadCount,
                decrementUnreadCount,
            }}>
            {children}
        </NotificationContext.Provider>
    );
};

// Хук для использования контекста
export const useNotification = (): NotificationContextProps => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
