import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import styled from 'styled-components';
import BrandInfo from './BrandInfo';
import AsideList from './AsideList';
import Profile from './Profile';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import { palette } from '../../../styled/common';
import { routes, customerRoute } from 'constants/routes';
import UserNav from '../userNav';
import EditProfileModel from '../EditProfileModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Aside = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [editProfileModal, setEditProfileModel] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [openNotificationPopup, setOpenNotificationPopup] = useState(false);
    const [openAuthPopup, setOpenAuthPopup] = useState(false);

    const _user = JSON.parse(localStorage.getItem('user') || '{}');

    const doLogout = () => {
        _user?.user_login === 'customer'
            ? navigate(`/${_user?.user?.business_id?._id}/login`)
            : navigate(`/login`);
        localStorage.removeItem('user');
        localStorage.removeItem('url');
    };

    const openAuth = () => {
        if (openAuthPopup) {
            setOpenAuthPopup(false);
        } else {
            setOpenAuthPopup(true);
            setOpenNotificationPopup(false);
        }
    };

    const openNotification = () => {
        if (openNotificationPopup) {
            setOpenNotificationPopup(false);
        } else {
            setOpenAuthPopup(false);
            setOpenNotificationPopup(true);
        }
    };

    return (
        <>
            <Wrapper flexDirection={_user?.user?.business_id?.leftMenu === true && 'column'}>
                <BrandContent>
                    <BrandInfo />
                </BrandContent>
                <Row>
                    <List lg={12} display={_user?.user?.business_id?.leftMenu === true && true}>
                        <AsideList
                            routes={_user?.user_login === 'customer' ? customerRoute : routes}
                        />
                        {_user?.user?.business_id?.leftMenu === true && (
                            <CustomCol col={12} onClick={() => doLogout()}>
                                <i className="far fa-sign-out"></i>
                                {t('Logout')}
                            </CustomCol>
                        )}
                    </List>
                </Row>
                {_user?.user?.business_id?.leftMenu === false && (
                    <ProfileRow>
                        <UserNav
                            openNotification={openNotification}
                            openNotificationPopup={openNotificationPopup}
                            openAuth={openAuth}
                            openAuthPopup={openAuthPopup}
                            userData={_user}
                            onClick={() => {
                                setOpenAuthPopup(!openAuthPopup);
                            }}
                            icon={_user?.user?.business_id?.leftMenu === true && true}
                        />
                    </ProfileRow>
                )}
                {_user?.user?.business_id?.leftMenu === true && (
                    <WrapperCustom>
                        <Profile
                            onClick={() =>
                                _user?.user_login === 'user' && setEditProfileModel(true)
                            }
                        />
                    </WrapperCustom>
                )}
            </Wrapper>
            {editProfileModal && <EditProfileModel onClose={() => setEditProfileModel(false)} />}
        </>
    );
};

const Wrapper = styled(Container)<any>`
    position: ${({ flexDirection }) => (flexDirection ? 'fixed' : 'relative')};
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background: ${palette.header};
    box-shadow: 0 0.1rem 0.3rem 0 ${palette.grey.shadow};
    display: flex;
    flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'row')};
    font-size: 1rem;
    width: ${({ width }) => (width ? '18.5rem' : '100%')};
    max-width: ${({ width }) => (width ? '' : '100%')};
    justify-content: ${({ flexDirection }) => (flexDirection ? '' : 'space-between')};
    align-items: ${({ width }) => width !== true && 'center'};
    ${media.xs`
    display:none;
  `}
    ${media.sm`
    display:none;
  `}
    ${media.md`
	display:none;
  `}
    ${media.lg`
    display: flex;
  `}
`;

const List = styled(Col)<any>`
    width: 100%;
    flex: 1 1 100%;
    padding: 0rem;
    margin: 0rem;
    display: ${({ display }) => (display ? '' : 'flex')};
    align-items: center;
`;

const BrandContent = styled(Row)`
    align-items: center;
    display: flex;
`;

const ProfileRow = styled(Row)<any>`
    margin-right: 0rem;
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '')};
`;

const CustomCol = styled(Col)`
    padding: 1rem;
    cursor: pointer;
    margin-left: 1rem;
    color: ${palette.light};
    display: flex;
    font-size: 1rem;
    align-items: center;
    gap: 1rem;
    ${media.xs`
   display: flex;
  `}
    ${media.sm`
    display: flex;
  `}
    ${media.md`
    display: flex;
  `}
    ${media.lg`
    `}
`;

const WrapperCustom = styled.div<any>`
    position: ${({ flexDirection }) => (flexDirection ? 'fixed' : 'absolute')};
    bottom: 0;
    left: 0;
    z-index: 20;
`;

export default Aside;
