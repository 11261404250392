/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * NotificationCenter Component
 * Description: This component serves as a container for displaying user notifications.
 * It will include the NotificationList component to handle the display of grouped notifications.
 *
 */

import { Layout } from '@components/common';
import NotificationList from '@components/notifications/notificationList';

const NotificationCenter = () => {
    return (
        <>
            <NotificationList />
        </>
    );
};
NotificationCenter.Layout = Layout;
export default NotificationCenter;
