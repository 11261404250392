/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ServiceProviderList Component
 * Description: This component displays a list of service providers. It includes a search field, a list of service providers, and an option to add a new service provider. The list supports pagination and lazy loading for improved performance.
 *
 * Props:
 * - hasId: any - Indicates whether a specific service provider ID is present in the router query to get specific service provider data.
 *
 */

import React, {
    forwardRef,
    useCallback,
    useRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import {
    IServiceProvider,
    IGetServiceProviderResponse,
} from 'interfaces/serviceProvider.interface';
import useRouter from 'hooks/router';
import _ from 'lodash';
import SearchField from '@components/common/search/SearchField';
import ContentList from '@components/common/contentList/ContentList';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import Button from '@components/Button';
import { AddButton } from '@components/customers/customersList/CustomersList';
import { useTranslation } from 'react-i18next';
import CreateServiceProvider from '@components/team/create/createServiceProvider';
import { palette } from 'styled/common';

interface IProps {
    hasId: any;
}

const ServiceProviderList = forwardRef(({}, ref) => {
    useImperativeHandle(ref, () => ({
        getServiceProvider() {
            setServiceProviderData('');
            getAllServiceProvider();
        },
    }));
    const { t }: any = useTranslation();
    const navigate = useNavigate();
    const router = useRouter();
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState<any>('');
    const observer = useRef<IntersectionObserver>();
    const [serviceProvider, setServiceProvider] = useState<IGetServiceProviderResponse>();
    const [serviceProviderData, setServiceProviderData] = useState<any>([]);
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const [openSnackbar] = useSnackbar();
    const [createModel, setCreateModel] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

    useEffect(() => {
        if (pageNumber > 1) {
            loadServiceProvider();
        }
    }, [pageNumber]);

    useEffect(() => {
        if (serviceProvider !== undefined && serviceProvider.total !== 0) {
            setPageNumber(serviceProvider && serviceProvider.page);
            setValue((serviceProvider !== undefined && serviceProvider.query) ?? null);
            setQuery((serviceProvider !== undefined && serviceProvider.query) ?? null);
            setHasMore(true);
            return;
        }
        getAllServiceProvider();
    }, []);

    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {});
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const getAllServiceProvider = (id = null) => {
        setLoading(true);

        const currentPage = pageNumber > 0 ? pageNumber : 1;
        api.get(`/serviceProvider?page=${currentPage}&pageSize=${pageSize}`)
            .then((serviceProvider: any) => {
                setServiceProvider(serviceProvider.data);
                setServiceProviderData(serviceProvider.data.serviceProvider);
                setTotal(serviceProvider?.data?.total);

                if (
                    serviceProvider.data.serviceProvider?.length &&
                    router.query[Object.keys(router.query)[0]] === '' &&
                    window.innerWidth > 767
                ) {
                    navigate(
                        `/team/service-provider-list/${
                            id ? id : serviceProvider.data.serviceProvider[0]._id
                        }`
                    );
                }
                setHasMore(serviceProvider.data.serviceProvider?.length > 0);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const loadServiceProvider = () => {
        setLoading(true);
        api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}`)
            .then((res: any) => {
                setServiceProviderData((prev: any) => [...prev, ...res.data.serviceProvider]);
                setServiceProvider(res.data);
                setTotal(res.data.total);
                setHasMore(res.data.serviceProvider?.length > 0);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const searchServiceProvider = (query: string) => {
        let value = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value?.length <= 3) {
                value = value.replace(/[- .]/g, '');
            } else if (value?.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value?.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}&query=${value}`)
                .then((serviceProvider: any) => {
                    setLoading(false);
                    setServiceProviderData(serviceProvider.data.serviceProvider);
                    setServiceProvider(serviceProvider.data);
                    setHasMore(serviceProvider.data.serviceProvider?.length > 0);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        } else {
            setLoading(true);
            api.get(`/serviceProvider?page=${pageNumber}&pageSize=${pageSize}&query=${value}`)
                .then((serviceProvider: any) => {
                    setLoading(false);
                    setServiceProviderData(serviceProvider.data.serviceProvider);

                    setServiceProvider(serviceProvider.data);
                    setHasMore(serviceProvider.data.serviceProvider?.length > 0);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const searchServiceProviderDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(1);
            searchServiceProvider(searchVal);
        }, 300);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchServiceProviderDebounce(event.target.value);
    };

    const loadMore = () => {
        setPageNumber((prevPage: any) => prevPage + 1);
    };
    return (
        <>
            {createModel ? (
                <CreateServiceProvider
                    onClose={() => {
                        setCreateModel(false);
                        serviceProviderData[0]?._id &&
                            navigate(`/team/service-provider-list/${serviceProviderData[0]?._id}`);
                    }}
                    onSave={(e: any) => {
                        setCreateModel(false);
                        getAllServiceProvider(e);
                    }}
                />
            ) : (
                <Wrapper>
                    {loading && <LoaderOverlay />}
                    <CustomRow>
                        <CustomCol md={12} lg={12}>
                            <HeadingWrapper>
                                <CustomDiv>
                                    {_userData?.leftMenu === true && (
                                        <CloseButton to="/team">
                                            <i className="fal fa-chevron-left"></i>
                                        </CloseButton>
                                    )}
                                    <Header>{t('Service Providers')}</Header>
                                </CustomDiv>
                                <AddButton
                                    onClick={() => {
                                        router.navigate(`/team/service-provider-list/`);
                                        setCreateModel(true);
                                    }}>
                                    <i className="fal fa-plus"></i>
                                </AddButton>
                            </HeadingWrapper>
                            <OnSamllViewPort hasId={router.query['*']}>
                                <SearchField
                                    value={value}
                                    handleChange={handleChange}
                                    name={t(`Service Provider`)}
                                    setValue={() => {
                                        setValue('');
                                        if (value) {
                                            searchServiceProvider('');
                                        }
                                    }}
                                />

                                <ProvidersList>
                                    {serviceProviderData?.length
                                        ? serviceProviderData.map(
                                              (serviceProvider: IServiceProvider, i: any) => {
                                                  const queryId =
                                                      router.query[Object.keys(router.query)[0]];

                                                  return (
                                                      <ContentList
                                                          key={i}
                                                          queryId={`${
                                                              queryId === serviceProvider._id
                                                                  ? 'isActivelist'
                                                                  : ''
                                                          }`}
                                                          id={serviceProvider._id}
                                                          link={`/team/service-provider-list/${serviceProvider._id}`}
                                                          icon={serviceProvider?.photo}
                                                          name={serviceProvider.name}
                                                          email={serviceProvider?.email}
                                                          phone={serviceProvider.phone}
                                                          serviceProvider={true}
                                                      />
                                                  );
                                              }
                                          )
                                        : loading === false && (
                                              <NotFound>{t('No Service Provider found')}</NotFound>
                                          )}
                                    <div ref={loaderRef}></div>
                                </ProvidersList>
                                {query
                                    ? ''
                                    : (total >
                                          (pageNumber === 1 ? pageNumber + 0 : pageNumber + 1) *
                                              pageSize ||
                                          loading) && (
                                          <LoadMore>
                                              <Button
                                                  bgtype={'secondary'}
                                                  ifClicked={loadMore}
                                                  disabled={loading}
                                                  label={`${t('Load more Service Provider')} (${
                                                      total -
                                                      (pageNumber === 1
                                                          ? pageNumber + 0
                                                          : pageNumber + 1) *
                                                          pageSize
                                                  })`}></Button>
                                          </LoadMore>
                                      )}
                            </OnSamllViewPort>
                        </CustomCol>
                    </CustomRow>
                </Wrapper>
            )}
        </>
    );
});

const Wrapper = styled(Container)`
    flex: 0 0 35rem;
    margin-left: 0rem;
    padding: 0;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    ${media.xs`
    flex: 0 0 35rem;
    `}
    ${media.sm`
    margin-top: 1rem;
    flex: 0 0 35rem;
    `}
	${media.md`
    margin-top: 0rem;
     flex: 0 0 27rem;
    `}
	@media screen and (min-width: 1076px) and (max-width: 1140px) {
        flex: 0 0 27rem;
    }
    @media screen and (min-width: 1032px) and (max-width: 1075px) {
        flex: 0 0 18rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1031px) {
        flex: 0 0 16rem;
    }

    ${media.lg`
    margin-top: 1rem;
    `}
    ${media.xl`
    margin-top: 1rem;
    background-color: white;
`}
`;

const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
`;

const CustomCol = styled(Col)`
    padding: 0;
`;

const Header = styled.h1`
    font-size: 1.78rem;
    font-weight: 600;
    margin: 0;
`;

export const CloseButton = styled(Link)`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.75rem /* 30px */;
    line-height: 2.25rem /* 36px */;
`;

const ProvidersList = styled.div`
    margin-top: 1rem;
    height: 40rem;
    overflow: auto;
`;

const OnSamllViewPort = styled.span<IProps>`
    display: block;
    ${media.xs`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
    ${media.sm`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
	${media.md`
    display: block;
	`}
	${media.lg`
    display: block;
	`}
`;

const LoadMore = styled.div`
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
`;

export const NotFound = styled.div`
    display: flex;
    justify-content: center;
`;

export const CustomDiv = styled.div`
    display: flex;
    align-items: center;
`;

const HeadingWrapper = styled.div`
    padding: 1rem 1rem 0.5rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export default ServiceProviderList;
