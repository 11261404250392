/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * API Configuration
 * Description: This file configures an Axios instance for making API calls with the specified base URL and headers.
 */

import axios from 'axios';

//api call
export const api = axios.create({
    baseURL: `https://api.vanille.me`,
    headers: {
        'Content-Type': 'application/json',
    },
});

// export const api = axios.create({
//     baseURL: `http://localhost:3000`,
//     headers: {
//         'Content-Type': 'application/json',
//     },
// });

api.interceptors.request.use(
    request => {
        let user = JSON.parse(localStorage.getItem('user'));
        const isLoggedIn = user && user.accessToken;

        if (isLoggedIn) {
            request.headers.common.Authorization = `Bearer ${user.accessToken}`;
        }

        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);
